import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RootNavigationComponent } from './features/dashboard/navigation/root-nav/root-nav.component';
import { PatientEditPersonalComponent } from './features/dashboard/patients/patient-edit/patient-edit-personal/patient-edit-personal.component';
import { PatientEditComponent } from './features/dashboard/patients/patient-edit/patient-edit.component';
import { PatientRegisterComponent } from './features/dashboard/patients/patient-register/patient-register.component';
import { PatientsListComponent } from './features/dashboard/patients/patients-list/patients-list.component';
import { LoginComponent } from './features/public/login/login.component';
import { FormExitGuard } from './core/guards/form-exit.guard';
import { PatientEditImageDatabaseComponent } from './features/dashboard/patients/patient-edit/patient-edit-image-database/patient-edit-image-database.component';
import { PatientEditAttachmentsComponent } from './features/dashboard/patients/patient-edit/patient-edit-attachments/patient-edit-attachments.component';
import { RegistersComponent } from './features/dashboard/registers/registers.component';
import { UserGroupsListComponent } from './features/dashboard/registers/user-groups/user-groups-list/user-groups-list.component';
import { UserGroupRegisterComponent } from './features/dashboard/registers/user-groups/user-group-register/user-group-register.component';
import { PublicRouteGuard } from './core/guards/public-route.guard';
import { ProtectedRouteGuard } from './core/guards/protected-route.guard';
import { UserGroupEditComponent } from './features/dashboard/registers/user-groups/user-group-edit/user-group-edit.component';
import { PasswordRecoveryComponent } from './features/public/password-recovery/password-recovery.component';
import { PasswordSetComponent } from './features/public/password-set/password-set.component';
import { ClinicUsersListComponent } from './features/dashboard/registers/clinic-users/clinic-users-list/clinic-users-list.component';
import { ClinicFormComponent } from './features/dashboard/registers/clinics/clinic-form/clinic-form.component';
import { ClinicsListComponent } from './features/dashboard/registers/clinics/clinics-list/clinics-list.component';
import { ProfessionalsFormComponent } from './features/dashboard/registers/professionals/professionals-form/professionals-form.component';
import { ProfessionalsListComponent } from './features/dashboard/registers/professionals/professionals-list/professionals-list.component';
import { ExamsComponent } from './features/dashboard/exams/exams.component';
import { ExamListComponent } from './features/dashboard/exams/exam-list/exam-list.component';
import { ExamFormComponent } from './features/dashboard/exams/exam-form/exam-form.component';
import { OximetersComponent } from './features/dashboard/oximeters/oximeters.component';
import { OximeterListComponent } from './features/dashboard/oximeters/oximeter-list/oximeter-list.component';
import { OximeterFormComponent } from './features/dashboard/oximeters/oximeter-form/oximeter-form.component';
import { HistoricListComponent } from './features/dashboard/oximeters/historic-list/historic-list.component';
import { PatientPolysomnographyComponent } from './features/dashboard/patients/patient-edit/patient-polysomnography/patient-polysomnography.component';
import { SystemUsersListComponent } from './features/dashboard/registers/system-users/system-users-list/system-users-list.component';
import { ClinicTagFormComponent } from './features/dashboard/registers/clinic-tags/clinic-tag-form/clinic-tag-form.component';
import { SystemUsersFormComponent } from './features/dashboard/registers/system-users/system-users-form/system-users-form.component';
import { ClinicTagsListComponent } from './features/dashboard/registers/clinic-tags/clinic-tags-list/clinic-tags-list.component';
import { PatientTagsListComponent } from './features/dashboard/registers/patient-tags/patient-tags-list/patient-tags-list.component';
import { PatientTagFormComponent } from './features/dashboard/registers/patient-tags/patient-tag-form/patient-tag-form.component';
import { ProfessionsListComponent } from './features/dashboard/registers/professions/professions-list/professions-list.component';
import { ProfessionFormComponent } from './features/dashboard/registers/professions/profession-form/profession-form.component';
import { ProfessionalTagsListComponent } from './features/dashboard/registers/professional-tags/professional-tags-list/professional-tags-list.component';
import { ProfessionalTagFormComponent } from './features/dashboard/registers/professional-tags/professional-tag-form/professional-tag-form.component';
import { SpecialtiesListComponent } from './features/dashboard/registers/specialties/specialties-list/specialties-list.component';
import { SpecialtiesFormComponent } from './features/dashboard/registers/specialties/specialties-form/specialties-form.component';
import { ClinicUserFormComponent } from './features/dashboard/registers/clinic-users/clinic-user-form/clinic-user-form.component';
import { NaoEncontradoComponent } from './features/public/nao-encontrado/nao-encontrado.component';
import { ResetPasswordComponent } from './features/dashboard/registers/reset-password/reset-password.component';
import { ManualComponent } from './features/public/manual/manual.component';
import { QuestionsComponent } from './features/public/questions/questions.component';
import { CouponsListComponent } from './features/dashboard/registers/coupons/coupons-list/coupons-list.component';
import { CouponFormComponent } from './features/dashboard/registers/coupons/coupon-form/coupon-form.component';
import { RedirectToStoreComponent } from './features/public/redirect-to-store/redirect-to-store.component';
import { PatientComponent } from './features/patient/patient.component';
import { RegisterComponent } from './features/patient/register/register.component';
import { QuizComponent } from './features/patient/quiz/quiz.component';
import { ConfirmCodeComponent } from './features/patient/confirm-code/confirm-code.component';
import { PatientRouteGuard } from './core/guards/patient-route.guard';
import { SelectVideoComponent } from './features/patient/select-video/select-video.component';
import { ShowVideoComponent } from './features/patient/show-video/show-video.component';

const routes: Routes = [
  {
    path: 'baixar',
    component: RedirectToStoreComponent,
    canActivate: [PublicRouteGuard],
  },
  { path: '', pathMatch: 'prefix', redirectTo: 'login' },
  { path: 'cadastro', component: QuestionsComponent },
  { path: 'manual/:name', component: ManualComponent },
  { path: 'login', component: LoginComponent, canActivate: [PublicRouteGuard] },
  {
    path: 'passwordRecovery',
    component: PasswordRecoveryComponent,
    canActivate: [PublicRouteGuard],
  },
  {
    path: 'set_password/:code',
    component: PasswordSetComponent,
    canActivate: [PublicRouteGuard],
  },
  {
    path: 'nao-encontrado',
    component: NaoEncontradoComponent,
    canActivate: [ProtectedRouteGuard],
  },
  {
    path: 'pacientes',
    component: PatientComponent,
    children: [
      { path: 'cadastro', component: RegisterComponent },
      { path: 'cadastro/:phoneNumber', component: RegisterComponent },
      { path: 'confirmar-codigo', component: ConfirmCodeComponent },
      {
        path: 'questionario',
        component: QuizComponent,
        canActivate: [PatientRouteGuard],
      },
      {
        path: 'adicionar-video',
        component: SelectVideoComponent,
        canActivate: [ProtectedRouteGuard],
      },
      {
        path: 'questionario',
        component: QuizComponent,
        canActivate: [PatientRouteGuard],
      },
      { path: 'video/:code', component: ShowVideoComponent },
    ],
  },
  {
    path: 'dashboard',
    component: RootNavigationComponent,
    canActivate: [ProtectedRouteGuard],
    children: [
      { path: '', pathMatch: 'prefix', redirectTo: 'exams' },
      {
        path: 'exams',
        component: ExamsComponent,
        children: [
          { path: '', component: ExamListComponent },
          { path: 'register', component: ExamFormComponent },
          { path: 'edit/:id', component: ExamFormComponent },
        ],
      },
      {
        path: 'oximeter',
        component: OximetersComponent,
        children: [
          { path: '', component: OximeterListComponent },
          { path: 'register', component: OximeterFormComponent },
          { path: 'edit/:id', component: OximeterFormComponent },
          { path: 'historic/:id', component: HistoricListComponent },
        ],
      },
      {
        path: 'patients',
        children: [
          { path: '', component: PatientsListComponent },
          {
            path: 'register',
            component: PatientRegisterComponent,
            canDeactivate: [FormExitGuard],
          },
          {
            path: 'edit/:id',
            component: PatientEditComponent,
            children: [
              { path: '', pathMatch: 'prefix', redirectTo: 'personal' },
              { path: 'personal', component: PatientEditPersonalComponent },
              {
                path: 'imageDatabase',
                component: PatientEditImageDatabaseComponent,
              },
              {
                path: 'attachments',
                component: PatientEditAttachmentsComponent,
              },
              {
                path: 'polysomnography',
                component: PatientPolysomnographyComponent,
              },
            ],
          },
        ],
      },

      {
        path: 'registers',
        children: [
          {
            path: '',
            component: RegistersComponent,
            children: [
              { path: '', pathMatch: 'prefix', redirectTo: 'clinics' },
              {
                path: 'clinics',
                children: [
                  { path: '', component: ClinicsListComponent },
                  {
                    path: 'register',
                    component: ClinicFormComponent,
                    canDeactivate: [FormExitGuard],
                  },
                  {
                    path: 'edit/:id',
                    component: ClinicFormComponent,
                    canDeactivate: [FormExitGuard],
                  },
                ],
              },
              {
                path: 'clinicUsers',
                children: [
                  { path: '', component: ClinicUsersListComponent },
                  {
                    path: 'register',
                    component: ClinicUserFormComponent,
                    canDeactivate: [FormExitGuard],
                  },
                  {
                    path: 'edit/:id',
                    component: ClinicUserFormComponent,
                    canDeactivate: [FormExitGuard],
                  },
                  {
                    path: 'reset_password/:id',
                    component: ResetPasswordComponent,
                    canDeactivate: [FormExitGuard],
                  },
                ],
              },
              {
                path: 'professionals',
                children: [
                  { path: '', component: ProfessionalsListComponent },
                  {
                    path: 'register',
                    component: ProfessionalsFormComponent,
                    canDeactivate: [FormExitGuard],
                  },
                  {
                    path: 'edit/:id',
                    component: ProfessionalsFormComponent,
                    canDeactivate: [FormExitGuard],
                  },
                ],
              },
              {
                path: 'systemUsers',
                children: [
                  { path: '', component: SystemUsersListComponent },
                  {
                    path: 'register',
                    component: SystemUsersFormComponent,
                    canDeactivate: [FormExitGuard],
                  },
                  {
                    path: 'edit/:id',
                    component: SystemUsersFormComponent,
                    canDeactivate: [FormExitGuard],
                  },
                  {
                    path: 'reset_password/:id',
                    component: ResetPasswordComponent,
                    canDeactivate: [FormExitGuard],
                  },
                ],
              },
              {
                path: 'userGroups',
                children: [
                  { path: '', component: UserGroupsListComponent },
                  {
                    path: 'register',
                    component: UserGroupRegisterComponent,
                    canDeactivate: [FormExitGuard],
                  },
                  {
                    path: 'edit/:id',
                    component: UserGroupEditComponent,
                    canDeactivate: [FormExitGuard],
                  },
                ],
              },
              {
                path: 'professions',
                children: [
                  { path: '', component: ProfessionsListComponent },
                  {
                    path: 'register',
                    component: ProfessionFormComponent,
                    canDeactivate: [FormExitGuard],
                  },
                  {
                    path: 'edit/:id',
                    component: ProfessionFormComponent,
                    canDeactivate: [FormExitGuard],
                  },
                ],
              },
              {
                path: 'patientTags',
                children: [
                  { path: '', component: PatientTagsListComponent },
                  {
                    path: 'register',
                    component: PatientTagFormComponent,
                    canDeactivate: [FormExitGuard],
                  },
                  {
                    path: 'edit/:id',
                    component: PatientTagFormComponent,
                    canDeactivate: [FormExitGuard],
                  },
                ],
              },
              {
                path: 'clinicTags',
                children: [
                  { path: '', component: ClinicTagsListComponent },
                  {
                    path: 'register',
                    component: ClinicTagFormComponent,
                    canDeactivate: [FormExitGuard],
                  },
                  {
                    path: 'edit/:id',
                    component: ClinicTagFormComponent,
                    canDeactivate: [FormExitGuard],
                  },
                ],
              },
              {
                path: 'professionalTags',
                children: [
                  { path: '', component: ProfessionalTagsListComponent },
                  {
                    path: 'register',
                    component: ProfessionalTagFormComponent,
                    canDeactivate: [FormExitGuard],
                  },
                  {
                    path: 'edit/:id',
                    component: ProfessionalTagFormComponent,
                    canDeactivate: [FormExitGuard],
                  },
                ],
              },
              {
                path: 'specialties',
                children: [
                  { path: '', component: SpecialtiesListComponent },
                  {
                    path: 'register',
                    component: SpecialtiesFormComponent,
                    canDeactivate: [FormExitGuard],
                  },
                  {
                    path: 'edit/:id',
                    component: SpecialtiesFormComponent,
                    canDeactivate: [FormExitGuard],
                  },
                ],
              },
              {
                path: 'coupons',
                children: [
                  { path: '', component: CouponsListComponent },
                  { path: 'register', component: CouponFormComponent },
                  { path: 'edit/:id', component: CouponFormComponent },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  providers: [FormExitGuard],
  exports: [RouterModule],
})
export class AppRoutingModule {}
